import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import vi from 'vee-validate/dist/locale/vi.json'

import { validatorPositive, validatorPositiveB, validatorUrlValidator, validatorPassword, validatorCreditCard, validatorPasswordMin } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: '{_field_} của bạn phải lớn hơn 0 !',
})

export const positiveB = extend('positiveB', {
  validate: validatorPositiveB,
  message: '{_field_} của bạn phải lớn hơn hoặc bằng 0 !',
})


export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: '{_field_} của bạn phải chứa ít nhất một chữ hoa, một chữ thường, một ký tự đặc biệt và một chữ số',
})

export const passwordMin = extend('passwordMin', {
  validate: validatorPasswordMin,
  message: '{_field_} của bạn phải chứa ít nhất một chữ thường, và một chữ số',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: vi.messages,
    fields: {
      userName: {
        required: 'Tên tài khoản không được để trống',
      },
      userPassword: {
        required: 'Mật khẩu không được để trống',
        min: 'Mật khẩu phải nhập ít nhất 6 kí tự',
      },
      organizationBranchName: {
        required: 'Tên phòng ban không được để trống',
      },
      departmentId: {
        required: 'Phòng ban không được để trống',
      },
      codeEmployee: {
        required: 'Mã nhân viên không được để trống',
      },
      firstName: {
        required: 'Họ không được để trống',
      },
      lastName: {
        required: 'Tên không được để trống',
      },
      email: {
        email: 'Email phải là email hợp lệ',
      },
      type: {
        required: 'Loại nhân viên không được để trống',
      },
      phoneNumber: {
        required: 'Số điện thoại không được để trống',
        integer: 'Số điện thoại phải là số',
        max: 'Số điện thoại tối đa chỉ được nhập 11 số',
      },
      identityCard: {
        required: 'CMND/Căn cước công dân không được để trống',
      },
      activeStatus: {
        required: 'Trạng thái không được để trống',
      },
      city: {
        required: 'Thành phố không được để trông',
      },
      district: {
        required: 'Quận/Huyện không được để trống',
      },
      subDistrict: {
        required: 'Xã/Phương không được để trống',
      },
      workStartDate: {
        required: 'Ngày vào làm không được để trống',
      },
      gender: {
        required: 'Giới tính không được để trống',
      },
      houseNumber: {
        required: 'Số nhà không được để trống',
      },
      birthday: {
        required: 'Ngày sinh không được để trống',
      },
      salary: {
        integer: 'Lương phải là các số',
      },
      phone: {
        integer: 'Số điện thoại chỉ có thể có các kí tự số',
        max: 'Số điện thoại không thể có nhiều hơn 11 kí tự',
      },
      phoneGuardian: {
        required: 'Số điện thoại người giám hộ là bắt buộc',
        integer: 'Số điện thoại chỉ có thể có các kí tự số',
        max: 'Số điện thoại không thể có nhiều hơn 11 kí tự',
      },
      schoolBlockName: {
        required: 'Tên khối không được để trống',
      },
      namePromotion: {
        required: 'Tên chương trình không được để trống',
      },
      contentPromotion: {
        max: 'Nội dung không được nhập quá 500 ký tự, chữ số',
      },
      percentPromotion: {
        max: 'Tối đa chỉ được nhập 3 số',
      },
      organizationBranchAcronym: {
        email: 'Địa chỉ email phải là một địa chỉ email đúng',
        max: 'Email chỉ được nhập tối đa 300 chữ số',
        required: 'Email không được để trống',
      },
      username: {
        required: 'Tên đăng nhập không được để trống',
        max: 'Tên đăng nhập chỉ được nhập tối đa 300 chữ số',
      },
      nameDevice: {
        required: 'Tên vật dụng không được để trống',
      },
      codeDevice: {
        required: 'Mã không được để trống',
      },
      dateDevice: {
        required: 'Ngày nhập không được để trống',
      },
      totalQuantityDevice: {
        required: 'Số lượng không được để trống',
        integer: 'Số lương phải là số',
      },
      employeeId: {
        required: 'Nhân viên không được để trống',
      },
      assignDate: {
        required: 'Ngày cấp không được để trông',
      },
      quantityAllocation: {
        required: 'Số lượng không được để trống',
        integer: 'Số lượng phải là số',
      },
      numberSerial: {
        required: 'Số serial không được để trống',
      },
      statusAsset: {
        required: 'Trạng thái không được để trống',
      },
      assignPeople: {
        required: 'Người cấp không được để trống',
      },
    },
  },
})

// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
